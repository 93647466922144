
global.$ = global.jQuery = require('jquery');


//require('jquery-ui/ui/effects/effect-slide.js');
//require('jquery-ui/ui/widgets/datepicker.js');
//require('jquery-ui/ui/i18n/datepicker-hr.js');

window.bootstrap = require('bootstrap');

require('@popperjs/core');
//require('owl.carousel');
require('magnific-popup');
require('jquery-form');
window.Swal = require('sweetalert2');
//require('jquery-zoom');
//require('swiper/bundle');
require('select2');

// Pixel
window.Vivus = require('vivus');
window.Headroom = require('headroom.js');
window.SmoothScroll = require('smooth-scroll');
const d = document;

//require('choices.js');
    if (d.querySelector('.headroom')) {
        var headroom = new Headroom(document.querySelector("#navbar-main"), {
            offset: 0,
            tolerance: {
                up: 0,
                down: 0
            },
        });
        headroom.init();
    }
    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true
    });
    var preloader = d.querySelector('.preloader');
    if (preloader) {

        const animations = ['oneByOne', 'delayed', 'sync', 'scenario'];

        new Vivus('loader-logo', { duration: 80, type: 'oneByOne' }, function () { });

        setTimeout(function () {
            preloader.classList.add('show');
        }, 1500);
    }

//require('waypoints/lib/jquery.waypoints.min.js');
//require('jcarousel');
//require('bootstrap-star-rating');
//require('./_pixel.js');
require('./_notifications.js');
require('./_forms.js');
require('./_extreme.js');
require('./_cookies.js');
//require('./_search.js');
require('./_swiper.js');
//require('./_shop.js');
require('./_user.js');
/*
try {
    require('./http.js');
    require('./newsletter.js');
    require('./cookies.js');
} catch (e) {
    console.log(e);
}
*/



